.hrefWithBackgroundWhite {
    color: black;
    text-decoration: none;
    text-transform: none;
}

a {
    color: white;
    text-decoration: none;
    text-transform: none;
}
