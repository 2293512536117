/*convert Link to Button*/
.link {
    text-decoration: none;
    color: white;
    background-color: #007bff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
    font-size: 14px;
    font-weight: 500;
    transition: 0.3s;
}