.linkbar {
    justify-content: center;
    padding: 0 20px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e9ecef;
}

.linkbar a {
    color: #495057;
    text-decoration: none;
    padding: 10px 0;
}

.linkbar li {
    list-style-type: none;
    /* Definir lado a lado */
    display: inline-block; /* ou display: inline-flex; */
    margin-right: 10px;
}
